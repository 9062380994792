<he-page-title appearance="heading-2">Home | Design Center</he-page-title>

<section style="margin-top: var(--he-spacing-2x-large)">
  <he-text tag="h2" appearance="heading-5">Tools</he-text>

  <div class="workspace-cards">
    <a [href]="transformationCenterUrl" class="b-card-container" data-testid="helloworld">
      <he-card heading="Transformations" class="interactive-card" >
        <he-text appearance="paragraph">
          Manage Transformation Templates.
        </he-text>
      </he-card>
    </a>
  </div>
</section>


