import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { scope } from 'src/scope';
import { AppComponent } from './app.component';
import { FeatureNoAccessComponent } from './components/feature-no-access/feature-no-access.component';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';

scope.registerComponent();
@NgModule({
  declarations: [
    AppComponent,
    FeatureNoAccessComponent,
    HomeComponent,
    LayoutComponent,
    NoAccessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
