<he-fly-in-panel id="heNoAccessPanel" heading="Request access" size="small" #heNoAccessPanel
    (he-request-close)="onPanelDismiss($event)">
      <p>
        You don't have permissions to use this feature.
      </p>
      <div>
        If you need to use this feature, request access from 
        <he-button
          style="margin: 0px"
          >xxxxxx&#64;xxx.com</he-button
        >
      </div>
  </he-fly-in-panel>
