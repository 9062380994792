import { Component } from '@angular/core';

@Component({
  selector: 'app-feature-no-access',
  templateUrl: './feature-no-access.component.html',
  styleUrls: ['./feature-no-access.component.scss']
})
export class FeatureNoAccessComponent {
  onPanelDismiss(ev: any) {}
}
